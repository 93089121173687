<p-dialog [modal]="true" [(visible)]="showBusy" [closable]="false" appendTo="body" [showHeader]="false" [style]="{'box-shadow':'none'}" [contentStyle]="{'background':'rgba(0,0,0,0)'}">
  <p-progressSpinner [style]="{'background':'rgba(0,0,0,0)','width':'50px','height':'50px'}" animationDuration=".5s" strokeWidth="8" ></p-progressSpinner>
</p-dialog>


<p-overlayPanel [dismissable]="false" #newPlan>
  <div class="m-2 formgrid grid w12" style="width:500px">
    
    <div class="field col-12 align-items-center justify-content-center">
      <app-company-search [company]="pendingNewCompany" (companySelected)="companySelected($event)"></app-company-search>
    </div>
    <div class="field col-2 align-items-center justify-content-center">
      <p-checkbox [(ngModel)]="pendingNewPlan.isSubscriber" [binary]="true" label="Subscriber" (onChange)="subscriberChanged($event)"></p-checkbox>
    </div>
    <div class="field col-5">
      <app-phi-search *ngIf="!pendingNewPlan.isSubscriber" [limitToSubscribers]="true" (phiSelected)="subscriberSelected($event)"></app-phi-search>
    </div>
    <div class="field col-5">
      <span *ngIf="!pendingNewPlan.isSubscriber && pendingNewPlanSubscriber != null" class="p-float-label">
        <p-dropdown appendTo="body" id="subscriberPlans" [options]="pendingNewPlanSubscriber.plans" [(ngModel)]="pendingNewPlan.dependentOf" optionLabel="memberId" (onChange)="subscriberPlanSelected($event)" [autoDisplayFirst]="false"></p-dropdown>
        <label for="subscriberPlans">Plan</label>
      </span>
    </div>
  
  <!-- Second Row -->

    <div class="field col-3">
      <span class="p-float-label">
        <p-dropdown appendTo="body" id="patientNetwork" dataKey="networkId" [options]="availableNetworks" [(ngModel)]="pendingNewPlan.network" optionLabel="networkName" [autoDisplayFirst]="false"></p-dropdown>
        <label for="patientNetwork">Network</label>
      </span>
    </div>
    <div class="field col-3">
      <span class="p-float-label">
        <input id="memberId" type="text" pInputText [(ngModel)]="pendingNewPlan.memberId"/>
        <label for="memberId">Member Id</label>
      </span>
    </div>
    <div class="field col-3">
      <span class="p-float-label">
        <p-dropdown appendTo="body" id="patientPlanType" [options]="util.planTypes" [(ngModel)]="pendingNewPlan.planType" optionLabel="planType" [autoDisplayFirst]="false"></p-dropdown>
        <label for="patientPlanType">Plan Type</label>
      </span>
    </div>
    <div class="field col-3 align-items-center justify-content-center">
      <p-checkbox [(ngModel)]="pendingNewPlan.hsaEligible" [binary]="true" label="HSA?"></p-checkbox>
    </div>
  </div> 

  <div class="flex justify-content-center">
    <span class="p-buttonset">

      <button pButton pRipple label="Cancel" (click)="cancelNewPlan(newPlan)" class="p-button-rounded p-button-danger"></button>
      <button [disabled]= "!formComplete()" pButton pRipple label="Save" (click)="confirmNewPlan($event,newPlan)"
              class="p-button-rounded p-button-success"></button>
    </span>
  </div>
</p-overlayPanel>


<div class="grid">
  <div class="col-10">
    <div class="category-header">PLAN
    <div style="float:right; font-size: .9em"><input type="checkbox" [(ngModel)]="showExpired">Show Expired Plans</div></div>
  </div>
  <div class="col-2">
    <span pTooltip="Member Already Has Active Plan" [tooltipDisabled]="phi.currentActivePlan == null">
      <button [disabled]="phi.currentActivePlan != null" pButton pRipple icon="pi pi-plus" class="p-button-rounded p-button-sm" (click)="newPlan.show($event)"></button>
    </span>  
  </div>
  <div class="col-12">

    <table class="data-table">
      <div *ngFor="let plan of plans">
      <ng-container *ngIf="plan.active || showExpired">
      <tr>
        <td class="field-name">Member ID</td>
        <td class="field-data"><span class="p-2">{{plan.memberId}}</span>

          <p-overlayPanel [dismissable]="false" #planStatusEditor>
            <div class="form-grid grid w12" style="width:300px">
              <div class="col-8 field">
                  <span class="p-float-label">
                      <p-dropdown id="status" [(ngModel)]="pendingPlanStatus" [options]="planStatuses" optionLabel="label" optionValue="value"
                        [autoDisplayFirst]="false" dataKey="value"></p-dropdown>
                      <label for="status">Plan Status</label>
                  </span>
              </div>
              <div class="col-4">
                <div class="formgroup-inline">
                  <span><p-button icon="pi pi-check" class="p-button-sm" (click)="commitStatusChange(plan); planStatusEditor.hide()"></p-button></span>
                  <span><p-button icon="pi pi-times" class="p-button-sm" (click)="planStatusEditor.hide()"></p-button></span>
                </div>
              </div>
          </div>
          </p-overlayPanel>

          <span *ngIf="planEditable(plan)" [style]="{'cursor':'pointer'}">
            <p-tag *ngIf="!plan.active" severity="danger"  (click)="preparePendingStatus(plan); planStatusEditor.show($event)">Expired</p-tag>
            <p-tag *ngIf="plan.active" severity="success" (click)="preparePendingStatus(plan); planStatusEditor.show($event)">Active</p-tag>
          </span>
          <span *ngIf="!planEditable(plan)" [style]="{'cursor':'unset'}">
            <p-tag *ngIf="!plan.active" severity="danger">Expired</p-tag>
            <p-tag *ngIf="plan.active" severity="success">Active</p-tag>
          </span>
        </td>
      </tr>
      <tr *ngIf="plan.network!=null">
        <td class="field-name">Network</td>
        <td class="field-data">{{plan.network.networkName}}</td>
      </tr>
      <tr *ngIf="plan.company!=null">
        <td class="field-name">Company</td>
        <td class="field-data">{{plan.company.companyName}}</td>
      </tr>
      <tr *ngFor="let source of plan.network.sources">
        <td class="field-name">Source</td>
        <td class="field-data">{{source.sourceName}}</td>
      </tr>
      <tr *ngIf="plan.network.carrier">
        <td class="field-name">Carrier</td>
        <td class="field-data">{{plan.network.carrier.carrierName}}</td>
      </tr>
      <!--
      <tr *ngIf="currentMember.company.companyName">
        <td class="field-name">Company</td>
        <td class="field-data">{{currentMember.company.companyName}}</td>
      </tr>
    -->
      <tr *ngIf="plan != null && plan.planType != null">
        <td class="field-name">Plan Type</td>
        <td class="field-data">{{plan.planType.planType}}</td>
      </tr>
      <tr *ngIf="plan.effectiveDate">
        <td class="field-name">Effective Date</td>
        <td class="field-data">{{plan.effectiveDate.toString().substr(5,2)}}/{{plan.effectiveDate.toString().substr(8,2)}}/{{plan.effectiveDate.toString().substr(0,4)}}</td>
      </tr>
      <tr *ngIf="plan.isSubscriber">
        <td class="field-name">Is Subscriber</td>
        <td class="field-data"><span *ngIf="plan.isSubscriber">YES</span><span *ngIf="!plan.isSubscriber">NO</span></td>
      </tr>
      <tr *ngIf="plan.hsaEligible">
        <td class="field-name">HSA Eligible</td>
        <td class="field-data"><span *ngIf="plan.hsaEligible">YES</span><span *ngIf="!plan.hsaEligible">NO</span></td>
      </tr>
      <!--
      <tr *ngIf="plan.detailsOutpatientSurgery">
        <td class="field-name">Outpatient Surgery</td>
        <td class="field-data">{{plan.detailsOutpatientSurgery}}</td>
      </tr>
      <tr *ngIf="plan.detailColonoscopy">
        <td class="field-name">Colonoscopy</td>
        <td class="field-data">{{plan.detailColonoscopy}}</td>
      </tr>
      <tr *ngIf="plan.detailLabs">
        <td class="field-name">Labs</td>
        <td class="field-data">{{plan.detailLabs}}</td>
      </tr>
      <tr *ngIf="plan.detailUrgentCare">
        <td class="field-name">Urgent Care</td>
        <td class="field-data">{{plan.detailUrgentCare}}</td>
      </tr>
      <tr *ngIf="plan.detailRadiology">
        <td class="field-name">Radiology</td>
        <td class="field-data">{{plan.detailRadiology}}</td>
      </tr>
      <tr *ngIf="plan.detailOccTherapy">
        <td class="field-name">Occupational Therapy</td>
        <td class="field-data">{{plan.detailOccTherapy}}</td>
      </tr>
      <tr *ngIf="plan.detailPt">
        <td class="field-name">Physical Therapy</td>
        <td class="field-data">{{plan.detailPt}}</td>
      </tr>
    -->
    </ng-container>
    </div>
    </table>
  </div>
</div>

