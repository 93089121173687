<!-- Busy Dialog -->
<p-dialog [modal]="true" [(visible)]="showBusy" [closable]="false" appendTo="body" [showHeader]="false" [style]="{'box-shadow':'none'}" [contentStyle]="{'background':'rgba(0,0,0,0)'}">
  <p-progressSpinner [style]="{'background':'rgba(0,0,0,0)','width':'50px','height':'50px'}" animationDuration=".5s" strokeWidth="8" ></p-progressSpinner>
</p-dialog>

<div class="fill-vert">

  <div style="border:1px solid black; padding: 5px;">
    <p-steps [model]="statusCodes" [(activeIndex)]="statusHighlight"></p-steps>
  </div>

  <br>
  
  <div class="grid scroll-overflow">
    <div class="col-4 p-2">
      <p-inplace #callerSelect>
        <ng-template pTemplate="display">
          <div style="margin-top: -17px">
          <app-phi-summary header="CONTACT PERSON" [phi]="encounter.caller.phi" [editablePhi]="false" [showAbbreviated]="true"></app-phi-summary>
          </div>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="category-header">CONTACT PERSON</div>
          <p-dropdown [options]="planMembers" [(ngModel)]="pendingEncounterCaller" optionValue="phi.firstActivePerson"
                      placeholder="Select Contact" (onChange)="updateCaller($event);callerSelect.deactivate()">
            <ng-template let-pm pTemplate="item">
              <div class="pm-item">
                <span *ngIf="pm.isSubscriber"><i class="pi pi-id-card"></i>&nbsp;</span>
                <span *ngIf="!pm.isSubscriber"><i class="pi pi-user"></i>&nbsp;</span>
                <span *ngIf="pm.phi.phiId==encounter.patient.phi.phiId"><i class="pi pi-bookmark-fill"></i>&nbsp;</span>
                <b>{{pm.phi.firstName}}</b> {{pm.phi.lastName}}
              </div>
            </ng-template>
          </p-dropdown>
          <br><br>
        </ng-template>
      </p-inplace>
      
      <div *ngIf="encounter.encounterType.encounterTypeId != 2">

        <div class="category-header">ENCOUNTER SUMMARY (# {{encounter.encounterId}})</div>

        <!--
        <p-overlayPanel #savingsPie>
          <ng-template pTemplate>
          <div class="flex align-items-center justify-content-center">
            <p-chart type="pie" [data]="encSavings" [options]="chartOptions"  width="250px" height="250px"></p-chart>
      <p-overlayPanel #savingsPie>
        <ng-template pTemplate>
        <div class="flex align-items-center justify-content-center">
          <p-chart type="pie" [data]="encSavings" [options]="chartOptions"  width="250px" height="250px"></p-chart>
        </div>
       </ng-template>
      </p-overlayPanel>
    -->
      <p-overlayPanel #closureReason>
        <ng-template pTemplate>
        <div class="flex align-items-center justify-content-center">
          {{encounter.closureReason?.reasonDescription}}
        </div>
       </ng-template>
      </p-overlayPanel>

      <p-overlayPanel #statusDialog>
        <div class="flex grid">
          <div class="col-12 w12 flex mt-3 mb-3">
            <span class="p-float-label" style="width:100%">
              <p-dropdown appendTo="body" id="encounterStatus" [options]="util.encounterStatuses" [(ngModel)]="pendingStatus" optionLabel="statusDescription" [autoDisplayFirst]="false"></p-dropdown>
              <label for="encounterStatus">Encounter Status</label>
            </span>
          </div>
          <div *ngIf="pendingStatus.statusCode == closed || encounter.status.statusCode == closed || pendingStatus.statusCode == canceled || encounter.status.statusCode == canceled" class="col-12 w12 flex mt-3 mb-3">
            <span class="p-float-label" style="width:100%">
              <p-dropdown appendTo="body" id="closureReason" [showClear]="true"  [options]="pendingStatus.availableClosureReasons" [(ngModel)]="pendingClosureReason" optionLabel="reasonDescription" [autoDisplayFirst]="false"></p-dropdown>
              <label for="closureReason">Closure Reason</label>
            </span>
          </div>
          <div class="col-12 w12 flex justify-content-center align-items-center mt-2 mb-2">
            <span class="p-buttonset">
              <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="statusDialog.hide(); cancelStatusChange($event)"></button>
              <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success" (click)="confirmStatusChange($event)"></button>
            </span>
          </div>
        </div>
      </p-overlayPanel>

      <!-- <p-overlayPanel #encounterDates>
        <ng-template pTemplate>
          <div class="flex grid" style="width:200px">
            <div class="col-12">
              <div class="flex grid">
                <div class="col-4">
                  <b>Created</b>
                </div>
                <div class="col-8">
                  {{encounter.creationDate | date: 'shortDate'}}
                </div>
              </div>
            </div>
            <div *ngIf="encounter.confirmedDate != null" class="col-12">
              <div class="flex grid">
                <div class="col-4">
                  Confirmed
                </div>
                <div class="col-8">
                  {{encounter.confirmedDate | date: 'shortDate'}}
                </div>
              </div>
            </div>
            <div *ngIf="encounter.closedDate != null" class="col-12">
              <div class="flex grid">
                <div class="col-4">
                  Closed
                </div>
                <div class="col-8">
                  {{encounter.closedDate | date: 'shortDate'}}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        </p-overlayPanel>

        <p-overlayPanel #closureReason>
          <ng-template pTemplate>
          <div class="flex align-items-center justify-content-center">
            {{encounter.closureReason?.reasonDescription}}
          </div>
        </ng-template>
        </p-overlayPanel>

        <p-overlayPanel #statusDialog>
          <div class="flex grid">
            <div class="col-12 w12 flex mt-3 mb-3">
              <span class="p-float-label" style="width:100%">
                <p-dropdown appendTo="body" id="encounterStatus" [options]="util.encounterStatuses" [(ngModel)]="pendingStatus" optionLabel="statusDescription" [autoDisplayFirst]="false"></p-dropdown>
                <label for="encounterStatus">Encounter Status</label>
              </span>
            </div>
            <div *ngIf="pendingStatus.statusCode == closed || encounter.status.statusCode == closed" class="col-12 w12 flex mt-3 mb-3">
              <span class="p-float-label" style="width:100%">
                <p-dropdown appendTo="body" id="closureReason" [showClear]="true"  [options]="util.closureReasons" [(ngModel)]="pendingClosureReason" optionLabel="reasonDescription" [autoDisplayFirst]="false"></p-dropdown>
                <label for="closureReason">Closure Reason</label>
              </span>
            </div>
            <div class="col-12 w12 flex justify-content-center align-items-center mt-2 mb-2">
              <span class="p-buttonset">
                <button pButton pRipple label="Cancel" class="p-button-small p-button-rounded p-button-danger" (click)="statusDialog.hide(); cancelStatusChange($event)"></button>
                <button pButton pRipple label="Save" class="p-button-small p-button-rounded p-button-success" (click)="confirmStatusChange($event)"></button>
              </span>
            </div>
          </div>
        </p-overlayPanel> -->

        <p-overlayPanel #encounterDates>
          <ng-template pTemplate>
            <div class="flex grid" style="width:200px">
              <div class="col-12">
                <div class="flex grid">
                  <div class="col-4">
                    <b>Created</b>
                  </div>
                  <div class="col-8">
                    {{encounter.creationDate | date: 'shortDate'}}
                  </div>
                </div>
              </div>
              <div *ngIf="encounter.confirmedDate != null" class="col-12">
                <div class="flex grid">
                  <div class="col-4">
                    Confirmed
                  </div>
                  <div class="col-8">
                    {{encounter.confirmedDate | date: 'shortDate'}}
                  </div>
                </div>
              </div>
              <div *ngIf="encounter.closedDate != null" class="col-12">
                <div class="flex grid">
                  <div class="col-4">
                    Closed
                  </div>
                  <div class="col-8">
                    {{encounter.closedDate | date: 'shortDate'}}
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </p-overlayPanel>

        <p-overlayPanel #appointmentDialog>
          <ng-template pTemplate>
            <div class="flex" style="width:900px">
              <app-appointment-editor [encounter]="encounter"></app-appointment-editor>
            </div>
          </ng-template>
        </p-overlayPanel>

        <p-overlayPanel #rewards>
          <ng-template pTemplate>
            <div style="width:600px">
              <div>
                <p-table [value]="transactions">
                  <ng-template pTemplate="caption">
                    Transactions
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width:7rem">
                        <!-- Status Tag -->
                      </th>
                      <th style="width:10rem">Type</th>
                      <th style="width:4rem">Amount</th>
                      <th style="width:7rem">Created Date</th>
                      <th style="width:7rem">Appoved Date</th>
                      <th style="width:6rem">Check Number</th>
                      <th style="width:7rem">Check Date</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-transaction >
                    <tr>
                      <td style="width:10rem">{{transaction.transactionType.transactionDescription}}</td>
                      <td style="width:4rem">
                        <!-- Status tag -->
                        <p-tag *ngIf="transaction.approvedDate == null && transaction.check == null">Created</p-tag>
                        <p-tag *ngIf="transaction.approvedDate != null && transaction.check == null">Approved</p-tag>
                        <p-tag *ngIf="transaction.check != null">Paid</p-tag>
                      </td>
                      <td style="width:7rem">{{transaction.transactionMagnitude | currency}}</td>
                      <td style="width:7rem">{{transaction.createDate | date:'shortDate'}}</td>
                      <td style="width:7rem">{{transaction.approvedDate | date:'shortDate'}}</td>
                      <td style="width:6rem">{{transaction.check?.checkNumber}}</td>
                      <td style="width:7rem">{{transaction.check?.checkDate | date:'shortDate'}}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </ng-template>
        </p-overlayPanel>

        <!--<p-overlayPanel #rewardDescription><pre style="max-width:800px; white-space: pre-wrap;">{{encounter?.rewardRule?.descriptionText}}</pre></p-overlayPanel>-->

        <p-overlayPanel #reassignDialog>
          <ng-template pTemplate>
            <div class="flex grid">
              <div class="col-12 w12 flex mt-3 mb-3">
                <span class="p-float-label" style="width:100%">
                  <p-dropdown appendTo="body" dataKey="userId" id="assignedTo" [options]="util.activeNurses" [(ngModel)]="encounter.assignedTo" optionLabel="displayName" [autoDisplayFirst]="false" (onChange)="confirmAssignedTo($event)"></p-dropdown>
                  <label for="assignedTo">Assigned To</label>
                </span>
              </div>
            </div>
          </ng-template>
        </p-overlayPanel>

        <p-overlayPanel #rewardDescription >
          <ng-template pTemplate>
            <div class="flex grid">
              
            <!-- <p-editor *ngIf="encounter != null && encounter.rewardRule !=null" [formats]="['code-block']" [(ngModel)]="encounter.rewardRule.descriptionText" [style]="{'height':'350px','width':'350px'}" readonly="true"> -->
              <!-- <ng-template *ngIf="!editMode" pTemplate="header"> -->
                <!-- //empty -->
              <!-- </ng-template> -->
            <!-- </p-editor> -->
            <p-scrollPanel [style]="{'height':'400px','width':'800px'}">
              <div>
                  <pre style="max-width:800px; white-space: pre-wrap;">{{encounter?.rewardRule?.descriptionText}}</pre>
              </div>
            </p-scrollPanel>

          </div>
          </ng-template>
        </p-overlayPanel>

        <!-- <p-inplace closable="true" closeIcon="pi pi-check" (onDeactivate)="processStatusChange($event)">
          <ng-template pTemplate="display">
            <span class="field-data">{{encounter.status?.statusDescription}}</span>
          </ng-template>
          <ng-template pTemplate="content">
            <p-dropdown appendTo="body" id="encounterStatus" [options]="util.encounterStatuses" [(ngModel)]="pendingStatus" optionLabel="statusDescription" style="width:100px"></p-dropdown>
          </ng-template>
        </p-inplace> -->
      <!-- <app-encounter-summary [encounter]="encounter" (statusChange)="encounterStatusChanged($event)" (closureReasonChange)="encounterClosureReasonChanged($event)"></app-encounter-summary> -->
        <div class="grid flex">
          <!-- 2x6 grid -->

          <div class="col-4 align-items-center justify-content-center box">
            <!-- Category -->
            <div class="flex justify-content-center boxHeader">Category</div>
            <div *ngIf="encounter.category != null" class="flex justify-content-center boxContent">{{encounter.category?.categoryName}}</div>
            <div *ngIf="encounter.category == null" class="flex justify-content-center boxContent">N/A</div>
          </div>
          <div class="col-4 align-items-center justify-content-center box" >
            <!-- Savings -->
            <div class="flex justify-content-center boxHeader">Savings</div>
            <!-- <div  class="flex justify-content-center boxContent"  (mouseenter)="savingsPie.toggle($event)" (mouseout)="savingsPie.toggle($event)">{{ (encounter.confirmedProvider == null ? encounter.probableSavings : encounter.confirmedSavings) | currency }}</div> -->
            <div  class="flex justify-content-center boxContent">{{ (encounter.confirmedProvider == null ? encounter.probableSavings : encounter.confirmedSavings) | currency }}</div>
          </div>
          <div class="col-4 align-items-center justify-content-center box">
            <!-- Reward -->
            <div class="flex justify-content-center boxHeader">Reward</div>
            <div *ngIf="encounter.confirmedProvider != null && (encounter.status.statusCode == 5 || encounter.status.statusCode==4)" class="flex justify-content-center boxContent" (mouseenter)="loadTransactions(); rewards.toggle($event)" (mouseout)="rewards.toggle($event)">{{encounter.finalRewardAmount | currency}}</div>
            <div *ngIf="encounter.status.statusCode != 4 && encounter.status.statusCode != 5" class="flex justify-content-center boxContent" (mouseenter)="rewardDescription.toggle($event)" (mouseout)="rewardDescription.toggle($event)">
              <span *ngIf="encounter.providerToBeat == null"> 
                N/A
              </span>
              <span *ngIf="encounter.providerToBeat != null">
                <span *ngIf="((encounter.confirmedProvider == null && (encounter.lowCostProviders == null || encounter.lowCostProviders.length == 0)))">
                  N/A
                </span>
              <span *ngIf="(encounter.confirmedProvider == null && (encounter.lowCostProviders != null && encounter.lowCostProviders.length >0)) ||
                            (encounter.confirmedProvider != null && (encounter.lowCostProviders == null || encounter.lowCostProviders.length == 0))" 
                            (click)="openRewardDialog($event)" style="cursor: pointer;" > 
                {{encounter.finalRewardAmount | currency}}  
              </span>
              </span>
              </div>          
            <div *ngIf="encounter.finalRewardAmount == null && encounter.rewardRule != null" class="flex justify-content-center boxContent" (mouseenter)="rewardDescription.toggle($event)" (mouseout)="rewardDescription.toggle($event)">N/A</div>

            <p-dialog [(visible)]="rewardDialogVisible">
              <p-card header="Select a Reward Rule">
                <div class="grid">
                  <div class="col-12">
                    <p-dropdown appendTo="body" dataKey="ruleId" id="rewardRuleSelection" [options]="encounterRewardRules" [(ngModel)]="tempRewardRule" [style]="{'width':'400px'}" optionLabel="ruleName"  [autoDisplayFirst]="false"></p-dropdown>
                  </div>
                  <div class="col-12 flex justify-content-center align-items-center">
                    <span class="p-buttonset">

                      <button pButton pRipple label="Cancel" (click)="cancelRewardRuleEdit()" class="p-button-rounded p-button-danger"></button>
                      <button pButton pRipple label="Save" (click)="saveNewRewardRuleSelection()" class="p-button-rounded p-button-success"></button>
                    </span>
                  </div>
                </div>  
              </p-card>
              
            </p-dialog>
          </div>
          <!-- Second Row -->
          <div class="col-4 align-items-center justify-content-center box" (click)="statusDialog.toggle($event)" style="cursor:pointer">
            <!-- Status -->
            <div class="flex justify-content-center boxHeader">Status</div>
            <div *ngIf="encounter.status.statusCode == closed || encounter.status.statusCode == canceled " class="flex justify-content-center boxContent" (mouseenter)="closureReason.toggle($event)" (mouseout)="closureReason.toggle($event)" >{{encounter.status?.statusDescription}}</div>
            <div *ngIf="encounter.status.statusCode != closed && encounter.status.statusCode != canceled" class="flex justify-content-center boxContent" >{{encounter.status?.statusDescription}}</div>

          </div>
          <div class="col-4 align-items-center justify-content-center box">
            <!-- Dates -->
            <div *ngIf="encounter.status.statusCode == open
                      || encounter.status.statusCode == notScheduled
                      || encounter.status.statusCode == scheduled" class="flex justify-content-center boxHeader">Created</div>
            <div *ngIf="encounter.status.statusCode == confirmed
                      || encounter.status.statusCode == complete
                      || encounter.status.statusCode == partiallyConfirmed
                      || encounter.status.statusCode == underReview" class="flex justify-content-center boxHeader">Confirmed</div>
            <div *ngIf="encounter.status.statusCode == closed" class="flex justify-content-center boxHeader">Closed</div>
            <div *ngIf="encounter.status.statusCode == canceled" class="flex justify-content-center boxHeader">Canceled</div>

            <div *ngIf="encounter.status.statusCode == open
                      || encounter.status.statusCode == notScheduled
                      || encounter.status.statusCode == scheduled"
                class="flex justify-content-center boxContent" (mouseenter)="encounterDates.toggle($event)" (mouseout)="encounterDates.toggle($event)">
              {{encounter.creationDate |date:'shortDate'}}
            </div>
            <div *ngIf="encounter.status.statusCode == confirmed
                      || encounter.status.statusCode == complete
                      || encounter.status.statusCode == partiallyConfirmed
                      || encounter.status.statusCode == underReview"
                class="flex justify-content-center boxContent" (mouseenter)="encounterDates.toggle($event)" (mouseout)="encounterDates.toggle($event)">
              {{encounter.confirmedDate |date:'shortDate'}}
            </div>
            <div *ngIf="encounter.status.statusCode == closed || encounter.status.statusCode == canceled "
                class="flex justify-content-center boxContent" (mouseenter)="encounterDates.toggle($event)" (mouseout)="encounterDates.toggle($event)">
              <span *ngIf="encounter.closedDate != null">
                {{encounter.closedDate | date:'shortDate'}}
              </span>
              <span *ngIf="encounter.closedDate == null" class="boxContent">
                N/A
              </span>
            </div>
          </div>
          <div class="col-4 align-items-center justify-content-center box" (click)="appointmentDialog.toggle($event)" style="cursor:pointer">
            <!-- Appointments -->
            <div *ngIf="encounter.mostRecentAppointment?.status?.statusCode == apptComplete || encounter.mostRecentAppointment?.status?.statusCode == apptCancelled" class="flex justify-content-center boxHeader">Last Appointment</div>
            <div *ngIf="encounter.mostRecentAppointment?.status?.statusCode == apptScheduled ||encounter.mostRecentAppointment == null " class="flex justify-content-center boxHeader">Next Appointment</div>
            <div *ngIf="encounter.mostRecentAppointment?.status?.statusCode == apptFirstAvailable" class="flex justify-content-center boxHeader">First Appointment</div>
            <!-- <div *ngIf="encounter.mostRecentAppointment?.status.statusCode == apptCancelled || encounter.mostRecentAppointment == null " class="flex justify-content-center">(Needs Appointment)</div> -->
            <!-- <div *ngIf="encounter.mostRecentAppointment?.status.statusCode == apptScheduled && encounter.mostRecentAppointment?.appointmentDate<now" class="flex justify-content-center">(Past Due)</div> -->
            <div class="flex justify-content-center boxContent" >
              <span *ngIf="encounter.mostRecentAppointment != null">
                {{encounter.mostRecentAppointment.appointmentDate | date:'shortDate'}}
              </span>
              <span *ngIf="encounter.mostRecentAppointment == null" class="boxContent">
                (not sched)
              </span>
            </div>

          </div>
          <div class="col-4 align-items-center justify-content-center box" (click)="preloadAssignedTo(); reassignDialog.toggle($event)" style="cursor:pointer">
            <div class="flex justify-content-center boxHeader">Assigned To</div>
            <div class="flex justify-content-center boxContent" >
              <p-avatar label="{{encounter.assignedTo?.firstName.substr(0,1)}}{{encounter.assignedTo?.lastName.substr(0,1)}}"
                        shape="circle" [style]="{'background-color':'#2196F3','color':'#FFFFFF'}"></p-avatar>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="col-4 p-2">
      <div class="flex justify-content-center" *ngIf="encounter.encounterType.encounterTypeId == 2">
        <span> This is a Registration Encounter</span>
      </div>
      <div *ngIf="encounter.encounterType.encounterTypeId != 2">

      <div class="category-header">PROCEDURES</div>
      <p-panel header="Procedure Selector" [collapsed]="true" [toggleable]="true">
        <app-procedure-selector [allowCategorySelection]="categorySelectionEnabled" [procedures]="procedures" [category]="encounter.category" (proceduresChange)="proceduresSelected($event)" (categoryChange)="categorySelected($event)"></app-procedure-selector>
      </p-panel>

      <p-table [value]="procedures">
        <!-- <ng-template pTemplate="caption">
          <div class="table-header">
              List of Selected Procedures
          </div>
        </ng-template> -->
        <!-- <ng-template pTemplate="header">
          <tr>
            <th style="width: 6rem;"></th>
            <th>Description</th>
          </tr>
        </ng-template> -->
        <ng-template pTemplate="body" let-procedure>
          <tr>
            <td style="width: 7rem;">
              <p-tag icon="pi pi-tag" ><span class="m-1">{{procedure.code}}</span></p-tag>
            </td>
            <td style="width: 20rem;">
              <span [pTooltip]="procedure.description">{{util.shortenDescription(procedure.displayText,55)}}</span>
            </td>
            <td>
              <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" (click)="removeProcedure(procedure)"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
<!--
      <div class="grid m-3">
        <div class="col-4">
          <p-tag> Original Cost: <p-divider layout="vertical"></p-divider> {{encounter.providerToBeat?.cost | currency}}</p-tag>
        </div>
        <div class="col-4">
          <p-tag> Potential Savings: <p-divider layout="vertical"></p-divider> {{encounter.lowestLowCostProvider?.cost | currency}}</p-tag>
        </div>
        <div class="col-4">
          <p-tag> Actual Savings: <p-divider layout="vertical"></p-divider> {{encounter.confirmedProvider?.cost | currency}}</p-tag>
        </div>
      </div> -->
          <div class="category-header">REFERRING PROVIDER</div>

          <div *ngIf="encounter.referringProvider != null" class="flex m-2 justify-content-center" style="width: 100%">
            <p-panel  header="{{encounter.referringProvider.providerLocation.practiceName}}" [toggleable]="true" [collapsed]="true" [style]="{'width':'100%'}">
              <app-encounter-provider [provider]="encounter.referringProvider" [showProviderInfo]="true"></app-encounter-provider>
              <div class="flex justify-content-center">
                <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-warning" (click)="deleteEncounterProvider(encounter.referringProvider)"></button>
              </div>
            </p-panel>
          </div>
          <div *ngIf="encounter.referringProvider == null" class="flex justify-content-center m-2">
            <button pButton pRipple type="button" label="Add Referring Provider" (click)="addReferringProvider($event)" class="p-button-rounded"></button>
            <p-dialog appendTo="body" header="Find Provider" [(visible)]="referringProviderSearchVisible" [style] ="{'max-width': '1200px', 'min-width':'800px', 'min-height':'700px', 'max-height': '800px' }">
              <div class="flex grid pt-4">
                <div class="col-5 w12">
                  <span class="p-float-label">
                    <p-autoComplete id="practiceSearch" [(ngModel)]="selectedReferringPractice" field="displayName" [suggestions]="referringPracticeSearchResults" (completeMethod)="practiceSearch($event)" [dropdown]="true" dropdownMode="current" (onSelect)="practiceSelectedFromSearch($event)" (onFocus)="selectedReferringProvider = null; selectedProvider = null">
                      <ng-template let-practice pTemplate="item">
                        <div>
                          <span style="margin-right: 5px;"><i class="pi pi-briefcase"></i></span>{{practice.displayName}}
                        </div>
                      </ng-template>
                    </p-autoComplete>
                    <label for="practiceSearch">Search By Practice</label>
                  </span>
                </div>
                <div class="col-2 flex justify-content-center">-- Or --</div>
                <div class="col-5 w12">
                  <span class="p-float-label">
                    <p-autoComplete id="providerSearch" [(ngModel)]="selectedReferringProvider" field="providerName" [suggestions]="referringProviderSearchResults" (completeMethod)="providerSearch($event)" [dropdown]="true" dropdownMode="current" (onSelect)="providerSelectedFromSearch($event)" (onFocus)="selectedReferringPractice = null; selectedPractice = null">
                      <ng-template let-provider pTemplate="item">
                        <div>
                          <span style="margin-right: 5px;"><i class="pi pi-briefcase"></i></span>{{provider.providerName}}
                        </div>
                      </ng-template>
                    </p-autoComplete>
                    <label for="providerSearch">Search By Provider</label>
                  </span>
                </div>
                <div class="col-12">
                  <app-practice-location-editor *ngIf="selectedPractice != null" [practice]="selectedPractice" [select]="true"
                                                (locationSelected)="referringProviderSelected($event)"></app-practice-location-editor>
                </div>
                <div class="col-12">
                  <app-practice-location-editor *ngIf="selectedProvider != null" [provider]="selectedProvider" [select]="true"
                                                (locationSelected)="referringProviderSelected($event)"></app-practice-location-editor>
                </div>
                <div class="flex col-12 justify-content-center">
                  <span class="p-buttonset">

                    <button pButton pRipple label="Cancel" (click)="referringProviderSearchVisible = false;" class="p-button-rounded p-button-danger"></button>
                    <button pButton pRipple label="Save" (click)="referringProviderSearchVisible = false; confirmedProviderSelection($event)"
                            class="p-button-rounded p-button-success"></button>
                  </span>
                </div>
              </div>
          </p-dialog>
        </div>

      <div  class="category-header">SERVICING PROVIDERS</div>

      <div class="flex justify-content-center m-2">
        <span class="p-buttonset" pTooltip="Member Has No Address" [tooltipDisabled]="hasActiveAddress" >
          <button pButton pRipple label="Add Provider To Beat" [disabled]="encounter.providerToBeat != null || !hasActiveAddress"
                  (click)="addProviderToBeat($event)" class="p-button-rounded" style="background-color: #F08080"></button>
          <button pButton pRipple label="Add Low Cost Option" [disabled]="!hasActiveAddress" (click)="addLowCostOption($event)" class="p-button-rounded"
                  style="background-color: #2196F3"></button>
          <button pButton pRipple label="Match" [disabled]="true"  (click)="matchEncounter($event)" class="p-button-rounded"
                  style="background-color: green"></button>
        </span>
      </div>

      <p-accordion>
        <div class="ptb">
        <p-accordionTab *ngIf="encounter.providerToBeat != null" header="{{encounter.providerToBeat.providerLocation.practiceName}} - {{encounter.providerToBeat.cost | currency }}">
          <app-encounter-provider [provider]="encounter.providerToBeat" [showProviderInfo]="true" ></app-encounter-provider>
          <div class="flex justify-content-center align-items-center m-2">
            <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-warning" (click)="deleteEncounterProvider(encounter.providerToBeat)"></button>
            <button pButton pRipple type="button" label="Copy As Low Cost" icon="pi pi-copy" (click)="copyProviderToBeatAsLowCost()"></button>
          </div>
        </p-accordionTab>
        </div>
        <p-accordionTab *ngFor="let lowCostProvider of encounter.lowCostProviders" class="lcp" header="{{lowCostProvider.providerLocation.practiceName}} - {{lowCostProvider.cost | currency }}">
          <app-encounter-provider [provider]="lowCostProvider"  [showProviderInfo]="true" ></app-encounter-provider>
          <div class="flex justify-content-center align-items-center m-2">
                <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-warning" (click)="deleteEncounterProvider(lowCostProvider)"></button>
          </div>
        </p-accordionTab>
        <p-accordionTab *ngIf="encounter.confirmedProvider != null" class="cp" header="{{encounter.confirmedProvider.providerLocation.practiceName}} - {{encounter.confirmedProvider.cost | currency }}">
          <app-encounter-provider [provider]="encounter.confirmedProvider"  [showProviderInfo]="true" ></app-encounter-provider>
        </p-accordionTab>
      </p-accordion>


      <!-- This is our procedure lookup component. We'll show it whenever we need to do a lookup! -->
      <p-dialog appendTo="body" header="Procedure Lookup" [(visible)]="procLookupVisible" [style] ="{'max-width': '1200px', 'max-height': '800px' }">
        <div>
          <app-procedure-lookup *ngIf="procLookupVisible"
            [(selectedProcs)]="procedures"
            [selectedCategory]="encounter.category"
            [zipCode]="encounter.patientZip"
            [radius]=25
            [lookbackDays]=60
            [initialStep]=3
            [network]="encounter.patientNetwork"
            (encounterProviderChange)="pendingEncounterProvider = $event"
            >
          </app-procedure-lookup>
        </div>
        <div class="flex justify-content-center align-items-center">
          <div>
            <span class="p-buttonset">
              <button pButton pRipple label="Cancel"  (click)="procLookupVisible = false;" class="p-button-rounded p-button-danger"></button>
              <button pButton pRipple label="Select" [disabled]="!encounterProviderReady()"
                      (click)="procLookupVisible = false; confirmLookupSelection();" class="p-button-rounded p-button-success"></button>
            </span>
          </div>
        </div>
      </p-dialog>


<!--
      <p-card>
        <ng-template pTemplate="header">
          <div class="grid justify-content-end align-items-center" style="width: 100%; height:50px">
              <div class="col-2">
                <button pButton pRipple type="button" icon="pi pi-plus" (click)="setProviderToBeat($event)" class="p-button-rounded p-button-outlined"></button>
              </div>
          </div>
        </ng-template>
      </p-card>

      <p-tabView>
        <p-tabPanel *ngIf="encounter.providerToBeat != null" header="Provider To Beat - {{encounter.providerToBeat.cost | currency }}">

        </p-tabPanel>
        <p-tabPanel *ngFor="let lowCostProvider of encounter.lowCostProviders" header="Low Cost Option - {{lowCostProvider.cost | currency }}">

        </p-tabPanel>
        <p-tabPanel *ngIf="encounter.confirmedProvider != null" header="Confirmed Provider - {{encounter.confirmedProvider.cost | currency }}">

        </p-tabPanel>
      </p-tabView> -->
      <!-- <table class="data-table" *ngFor="let procedure of encounter.procedures">
        <tr>
          <td class="field-data">{{procedure.procedure.code}}</td>
          <td class="field-data"><span *ngIf="procedure.primaryProcedure">PRIMARY PROCEDURE</span></td>
        </tr>
        <tr *ngIf="procedure.procedure.description">
          <td colspan="2" class="field-data">{{procedure.procedure.description}}</td>
        </tr>
      </table> -->

      <!-- <div class="category-header">ENCOUNTER PROVIDERS</div>

      <table class="data-table" *ngFor="let provider of encounter.encounterProviders">
        <tr>
          <td colspan="2" class="field-data">
            <span *ngIf="provider.providerToBeat" style="color:darkred">PROVIDER TO BEAT</span>
            <span *ngIf="provider.lowCostProvider" style="color:darkgreen">LOW-COST PROVIDER</span>
            <span *ngIf="provider.confirmedProvider" style="color:darkblue">CONFIRMED PROVIDER</span>
          </td>
        </tr>
        <tr *ngIf="provider.providerLocation.practiceName">
          <td class="field-name">Practice Name</td>
          <td class="field-data">{{provider.providerLocation.practiceName}}</td>
        </tr>
        <tr *ngIf="provider.providerLocation.providerOrganizationName">
          <td class="field-name">Organization Name</td>
          <td class="field-data">{{provider.providerLocation.providerOrganizataionName}}</td>
        </tr>
        <tr
          *ngIf="provider.providerLocation.location.street1 || provider.providerLocation.location.street2 || provider.providerLocation.location.city || provider.providerLocation.location.state || provider.providerLocation.location.zip">
          <td class="field-name">Address</td>
          <td class="field-data">
            <span *ngIf="provider.providerLocation.location.street1">{{provider.providerLocation.location.street1}}</span>&nbsp;
            <span *ngIf="provider.providerLocation.location.street2">{{provider.providerLocation.location.street2}}</span>
            <br>
            <span *ngIf="provider.providerLocation.location.city">{{provider.providerLocation.location.city}}</span>&nbsp;
            <span *ngIf="provider.providerLocation.location.state">{{provider.providerLocation.location.state}}</span>&nbsp;
            <span *ngIf="provider.providerLocation.location.zip">{{provider.providerLocation.location.zip}}</span>
          </td>
        </tr>
        <tr>
          <td class="field-name">Cost<span *ngIf="provider.cost != provider.costOverride"> OVERRIDE</span></td>
          <td class="field-data">
            <span *ngIf="provider.cost == provider.costOverride">{{provider.cost.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            })}}</span>
            <span *ngIf="provider.cost != provider.costOverride">{{provider.costOverride.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            })}}</span>
          </td>
        </tr>
      </table> -->

    </div>
    </div>

    <div class="col-4 p-2">

      <div class="category-header">NOTES | DIARIES | FILES</div>
        <p-accordion class="fill-vert">
          <p-accordionTab header="Diaries">
            <div style="height:500px">
              <app-diary-scroller [getForCurrentUser]="false" [referenceCode]=1 [relatedTo]="encounter.encounterId" rowBufferLen="5" ></app-diary-scroller>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Notes">
            <div style="height:500px">
              <app-note-scroller [referenceCode]="noteScrollerType" [relatedTo]="encounter.encounterId" rowBufferLen="5" ></app-note-scroller>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Files">
            <div style="height:500px">
              <app-file-scroller [encounter]="encounter.encounterId" ></app-file-scroller>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Faxes">
            <div style="height:500px">
              <app-fax-scroller [encounter]="encounter" ></app-fax-scroller>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Emails">
            <div style="height:500px">
              <app-email-scroller [encounter]="encounter"></app-email-scroller>
            </div>
          </p-accordionTab>
        </p-accordion>
    </div>

  </div>
</div>
