<!-- Busy Dialog -->
<p-dialog [modal]="true" [(visible)]="showBusy" [closable]="false" appendTo="body" [showHeader]="false" [style]="{'box-shadow':'none'}" [contentStyle]="{'background':'rgba(0,0,0,0)'}">
  <p-progressSpinner [style]="{'background':'rgba(0,0,0,0)','width':'50px','height':'50px'}" animationDuration=".5s" strokeWidth="8" ></p-progressSpinner>
</p-dialog>

<p-overlayPanel #filterPanel appendTo="body">
  <div class="flex grid" style="width:200px">

      <div class="col-12 w12 flex mt-3 mb-3">
      <span class="p-float-label" style="width:100%">
          <p-multiSelect appendTo="body" id="encounterType" [options]="availableEncounterTypes" [(ngModel)]="encounterTypeFilter" optionLabel="encounterType" ></p-multiSelect>
          <label for="encounterType">Encounter Types</label>
      </span>
      </div>
  </div>
</p-overlayPanel>  

<p-overlayPanel #newPatientPanel appendTo="body" [style]="{'width':'800px'}">
  <div class="flex flex-column">
      
      <div class="m-2">
        <p-card header="Patient">
          <div class="formgrid grid w12">
            <div class="field col-4">
              <span class="p-float-label">
                <input id="patientFirst" type="text" pInputText [(ngModel)]="pendingPatient.firstName"/>
                <label for="patientFirst">First Name</label>
              </span>
            </div>
            <div class="field col-2">
              <span class="p-float-label">
                <input id="patientMI" type="text" pInputText [(ngModel)]="pendingPatient.middleInitial"/>
                <label for="patientMI">MI</label>
              </span>
            </div>
            <div class="field col-4">
              <span class="p-float-label">
                <input id="patientLast" type="text" pInputText [(ngModel)]="pendingPatient.lastName"/>
                <label for="patientLast">Last Name</label>
              </span>
            </div>
            <div class="field col-2">
              <span class="p-float-label">
                <p-calendar id="patientDob"[(ngModel)]="pendingPatient.dob"></p-calendar>
                <label for="patientDob">Date Of Birth</label>
              </span>              
            </div>
            <!-- Second Row -->
            <div class="field col-3">
              <!-- <span class="p-float-label">
                <p-autoComplete inputId="patientCompany" [(ngModel)]="pendingCompany" field="companyName" [suggestions]="companySearchResults" (completeMethod)="companySearch($event)" [dropdown]="true" dropdownMode="current" (onSelect)="newCompanySelected($event)">
                  <ng-template let-company pTemplate="item">
                    <div>
                      <span style="margin-right: 5px;"><i class="pi pi-id-card"></i></span>
                      <span *ngIf="company.active" >{{company.displayName}}</span>
                      <span *ngIf="!company.active" style="color:red;" >{{company.displayName}}</span>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <label for="patientCompany">Company</label>
              </span> -->
              <app-company-search (companySelected)="newCompanySelected($event)"></app-company-search>
            </div>
            <div class="field col-2">
              <span class="p-float-label">
                <p-dropdown appendTo="body" id="patientNetwork" [options]="availableNetworks" [(ngModel)]="pendingNetwork" optionLabel="networkName"
                            [autoDisplayFirst]="false"></p-dropdown>
                <label for="patientNetwork">Network</label>
              </span>
            </div>
            <div class="field col-3">
              <span class="p-float-label">
                <input id="memberId" type="text" pInputText [(ngModel)]="pendingPatientMemberId"/>
                <label for="memberId">Member Id</label>
              </span>
            </div>
            <div class="field col-3">
              <span class="p-float-label">
                <p-dropdown appendTo="body" id="patientPlanType" [options]="tcemsUtility.planTypes" [(ngModel)]="pendingPlanType"
                            optionLabel="planType" [autoDisplayFirst]="false"></p-dropdown>
                <label for="patientPlanType">Plan Type</label>
              </span>
            </div>
            <div class="field col-1 align-items-center justify-content-center">
              <p-checkbox [(ngModel)]="pendingHSAEligible" [binary]="true" label="HSA?"></p-checkbox>
            </div>
          </div> 
        </p-card>
      </div>


<!-- Subscriber -->


      <div class="ml-2 mr-2 mb-2 mt-4">
        <p-checkbox [(ngModel)]="newPatientIsSubscriber" [binary]="true" label="Patient Is Subscriber"></p-checkbox>
      </div>
      <div class="m-2">
        <p-card *ngIf="!newPatientIsSubscriber" header="Subscriber">
          <p-tabView>
            <p-tabPanel [selected]="useExistingSubscriber" header="Existing">
              <div class="formgrid grid w12">
                <div class="field col-4">
                  <!-- <p-autoComplete placeholder="Search" [(ngModel)]="existingSubscriberSearchResult" field="displayName" [suggestions]="phiSearchResults" (completeMethod)="phiSearch($event)" (onSelect)="existingSubscriberSelected($event)" [dropdown]="true" dropdownMode="current">
                    <ng-template let-phi pTemplate="item">
                      <div>
                        <span style="margin-right: 5px;"><i class="pi pi-user"></i></span>{{phi.displayName}}
                      </div>
                    </ng-template>
                  </p-autoComplete> -->
                  <app-phi-search [limitToSubscribers]="true" (phiSelected)="existingSubscriberSelected($event)"></app-phi-search>
                </div>
                <div class="field col-4">
                  <span *ngIf="existingSubscriber != null" class="p-float-label">
                    <p-dropdown appendTo="body" id="subscriberPlans" [options]="existingSubscriber.plans" [(ngModel)]="existingSubscriberPlan"
                                optionLabel="memberId" (onChange)="existingSubscriberPlanSelected($event)" [autoDisplayFirst]="false"></p-dropdown>
                    <label for="subscriberPlans">Plan</label>
                  </span>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel header="New">
              <div class="formgrid grid w12">
                <div class="field col-4">
                  <span class="p-float-label">
                    <input id="subscriberFirst" type="text" pInputText [(ngModel)]="pendingSubscriber.firstName"/>
                    <label for="subscriberFirst">First Name</label>
                  </span>
                </div>
                <div class="field col-2">
                  <span class="p-float-label">
                    <input id="subscriberMI" type="text" pInputText [(ngModel)]="pendingSubscriber.middleInitial"/>
                    <label for="subscriberMI">MI</label>
                  </span>
                </div>
                <div class="field col-4">
                  <span class="p-float-label">
                    <input id="subscriberLast" type="text" pInputText [(ngModel)]="pendingSubscriber.lastName"/>
                    <label for="subscriberLast">Last Name</label>
                  </span>
                </div>
                <div class="field col-2">
                  <span class="p-float-label">
                    <p-calendar id="subscriberDob"[(ngModel)]="pendingSubscriber.dob"></p-calendar>
                    <label for="subscriberDob">Date Of Birth</label>
                  </span>              
                </div>
                <!-- Second Row -->
                <div class="field col-3">
                  <!-- <span class="p-float-label">
                    <p-autoComplete inputId="subscriberCompany" [(ngModel)]="pendingCompany" field="companyName" [suggestions]="companySearchResults" (completeMethod)="companySearch($event)" [dropdown]="true" dropdownMode="current" (onSelect)="newCompanySelected($event)">
                      <ng-template let-company pTemplate="item">
                        <div>
                          <span style="margin-right: 5px;"><i class="pi pi-id-card"></i></span>
                          <span *ngIf="company.active" >{{company.displayName}}</span>
                          <span *ngIf="!company.active" style="color:red;" >{{company.displayName}}</span>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                    <label for="subscriberCompany">Company</label>
                  </span> -->
                  <app-company-search (companySelected)="newCompanySelected($event)"></app-company-search>
                </div>   
                <div class="field col-2">
                  <span class="p-float-label">
                    <p-dropdown appendTo="body" id="subscriberNetwork" [options]="availableNetworks" [(ngModel)]="pendingNetwork"
                                optionLabel="networkName" [autoDisplayFirst]="false"></p-dropdown>
                    <label for="subscriberNetwork">Network</label>
                  </span>
                </div>
                <div class="field col-3">
                  <span class="p-float-label">
                    <input id="memberId" type="text" pInputText [(ngModel)]="pendingSubscriberMemberId"/>
                    <label for="memberId">MemberId</label>
                  </span>
                </div>
                <div class="field col-3">
                  <span class="p-float-label">
                    <p-dropdown appendTo="body" id="subscriberPlanType" [options]="tcemsUtility.planTypes" [(ngModel)]="pendingPlanType"
                                optionLabel="planType" [autoDisplayFirst]="false"></p-dropdown>
                    <label for="subscriberPlanType">Plan Type</label>
                  </span>
                </div>
                <div class="field col-1 align-items-center justify-content-center">
                  <p-checkbox [(ngModel)]="pendingHSAEligible" [binary]="true" label="HSA?"></p-checkbox>
                </div>
              </div> 
            </p-tabPanel>
          </p-tabView>
          
        </p-card>
      </div>

<!-- Caller -->

      <div class="ml-2 mr-2 mb-2 mt-4">
        <p-checkbox [(ngModel)]="newPatientIsCaller" [binary]="true" label="Patient Is Caller"></p-checkbox>
      </div>
      <div class="m-2">
        <p-card *ngIf="!newPatientIsCaller" header="Caller">
          <p-tabView>
            <p-tabPanel [selected]="useExistingSubscriber" header="Existing">
              <div class="formgrid grid w12">
                <div class="field col-4">
                  <!-- <p-autoComplete placeholder="Search" [(ngModel)]="existingCallerSearchResult" field="displayName" [suggestions]="phiSearchResults" (completeMethod)="phiSearch($event)" (onSelect)="existingCallerSelected($event)" [dropdown]="true" dropdownMode="current">
                    <ng-template let-phi pTemplate="item">
                      <div>
                        <span style="margin-right: 5px;"><i class="pi pi-user"></i></span>{{phi.displayName}}
                      </div>
                    </ng-template>
                  </p-autoComplete> -->
                  <app-phi-search [limitToSubscribers]="true" (phiSelected)="existingCallerSelected($event)"></app-phi-search>

                </div>
                <div class="field col-4">
                  <span *ngIf="existingCaller != null" class="p-float-label">
                    <p-dropdown appendTo="body" id="callerPlans" [options]="existingCaller.plans" [(ngModel)]="existingCallerPlan"
                                optionLabel="memberId" [autoDisplayFirst]="false"></p-dropdown>
                    <label for="callerPlans">Plan</label>
                  </span>
                </div>
              </div>

            </p-tabPanel>          
            <p-tabPanel>
              <div class="formgrid grid w12">
                <div class="field col-4">
                  <span class="p-float-label">
                    <input id="callerFirst" type="text" pInputText [(ngModel)]="pendingCaller.firstName"/>
                    <label for="callerFirst">First Name</label>
                  </span>
                </div>
                <div class="field col-2">
                  <span class="p-float-label">
                    <input id="callerMI" type="text" pInputText [(ngModel)]="pendingCaller.middleInitial"/>
                    <label for="callerMI">MI</label>
                  </span>
                </div>
                <div class="field col-4">
                  <span class="p-float-label">
                    <input id="callerLast" type="text" pInputText [(ngModel)]="pendingCaller.lastName"/>
                    <label for="callerLast">Last Name</label>
                  </span>
                </div>
                <div class="field col-2">
                  <span class="p-float-label">
                    <p-calendar id="callerDob"[(ngModel)]="pendingCaller.dob"></p-calendar>
                    <label for="callerDob">Date Of Birth</label>
                  </span>              
                </div>
                <!-- Second Row -->
                <div class="field col-3">
                  <!-- <span class="p-float-label">
                    <p-autoComplete inputId="callerCompany" [(ngModel)]="pendingCompany" field="companyName" [suggestions]="companySearchResults" (completeMethod)="companySearch($event)" [dropdown]="true" dropdownMode="current" (onSelect)="newCompanySelected($event)">
                      <ng-template let-company pTemplate="item">
                        <div>
                          <span style="margin-right: 5px;"><i class="pi pi-id-card"></i></span>
                          <span *ngIf="company.active" >{{company.displayName}}</span>
                          <span *ngIf="!company.active" style="color:red;" >{{company.displayName}}</span>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                    <label for="callerCompany">Company</label>
                  </span> -->
                  <app-company-search (companySelected)="newCompanySelected($event)"></app-company-search>
                </div>   
                <div class="field col-2">
                  <span class="p-float-label">
                    <p-dropdown appendTo="body" id="callerNetwork" [options]="availableNetworks" [(ngModel)]="pendingNetwork"
                                optionLabel="networkName" [autoDisplayFirst]="false"></p-dropdown>
                    <label for="callerNetwork">Network</label>
                  </span>
                </div>
                <div class="field col-3">
                  <span class="p-float-label">
                    <input id="memberId" type="text" pInputText [(ngModel)]="pendingCallerMemberId"/>
                    <label for="memberId">MemberId</label>
                  </span>
                </div>
                <div class="field col-3">
                  <span class="p-float-label">
                    <p-dropdown appendTo="body" id="callerPlanType" [options]="tcemsUtility.planTypes" [(ngModel)]="pendingPlanType"
                                optionLabel="planType" [autoDisplayFirst]="false"></p-dropdown>
                    <label for="callerPlanType">Plan Type</label>
                  </span>
                </div>
                <div class="field col-1 align-items-center justify-content-center">
                  <p-checkbox [(ngModel)]="pendingHSAEligible" [binary]="true" label="HSA?"></p-checkbox>
                </div>
              </div> 
            </p-tabPanel>
          </p-tabView>
        </p-card>
      </div>
      <div class="flex justify-content-center">
        <span class="p-buttonset">

          <button pButton pRipple label="Cancel" (click)="newPatientPanel.hide()" class="p-button-rounded p-button-danger"></button>
          <button pButton pRipple label="Save" (click)="confirmNewPerson($event,newPatientPanel)"
                  class="p-button-rounded p-button-success"></button>
        </span>
      </div>
  </div>
</p-overlayPanel>



    
<div class="grid fill-vert">
  <div class="col-2 shadow-5 p-2 fill-vert">
    <div class="flex-column fill-vert">

    <div
      class="category-header">
      <!-- {{loginStatus.currentUser?.firstName.substr(0, 1)}} {{loginStatus.currentUser?.lastName}} -->
      WORKBENCH
      <!-- <div style="float: right"> -->
      <!-- <div class="p-2">
        <p-checkbox name="showAllEncounters" label="Show All" binary="true"
                    [(ngModel)]="showAllEncounters"></p-checkbox>
      </div> -->
    </div>
    <div class="scroll-overflow fill-vert">
      <div class="flex grid m-2">
        <div class="col-8">
          <!-- Search Bar -->
          <!-- <p-autoComplete placeholder="Search" [(ngModel)]="selectedPhiSearchResult" field="displayName" [suggestions]="phiSearchResults" (completeMethod)="phiSearch($event)" [dropdown]="true" dropdownMode="current" (onSelect)="phiSelectedFromSearch($event)">
            <ng-template let-phi pTemplate="item">
              <div>
                <span style="margin-right: 5px;"><i class="pi pi-user"></i></span>{{phi.displayName}}
              </div>
            </ng-template>
          </p-autoComplete> -->
          <app-phi-search (phiSelected)="phiSelectedFromSearch($event)"></app-phi-search>
        </div>
        <div class="col-4 align-items-center justify-content-center">
          <button pButton pRipple type="button" label="New" class="p-button-rounded" (click)="newPatientPanel.toggle($event)"></button>
        </div>
      </div>
      <div>
        <p-tabView (onChange)="workbenchTabChanged($event)">
          <p-tabPanel header="Recent">
            <div *ngIf="recentEncounters != null && recentTabSelected">
              <p-table [value]="recentEncounters.recordsOut" [lazy]="true" (onLazyLoad)="loadMoreRecentEncounters($event)"
                [scrollable]="true" [virtualScroll]="true" dataKey="encounterId" [virtualRowHeight]="reRowHeight" [scrollHeight]="reScrollerHeight" [totalRecords]="recentEncounters.totalRecords"
                >
                <ng-template pTemplate="caption">
                  
                </ng-template>
                <ng-template pTemplate="body" let-encounter>
                  <tr [style]="{'min-height':reRowHeightInPixels,'height':reRowHeightInPixels,'max-height':reRowHeightInPixels}">
                    <td style="min-width: 100%;" class="w12">
                      <a (click)="loadUserEncounterFromPhi(encounter.patient.phi.phiId,encounter.encounterId)" [style]="{'cursor': 'pointer','min-width':'100%'}">
                      <div class="grid" style="min-width: 100%;">
                        <div class="col-6">
                            <div class="workbench-bold">{{encounter.patient.phi.lastName}}, {{encounter.patient.phi.firstName}}</div>
                            <div class="workbench-bold">{{encounter.patientPlan.memberId}}</div>
                        </div>
                        <div class="col-6">
                            <div class="workbench-bold">{{encounter.encounterId}}</div>
                            <div *ngIf="encounter.encounterType.encounterTypeId != 2" class="workbench-bold">{{encounter.category?.categoryName}}</div>
                            <div *ngIf="encounter.encounterType.encounterTypeId == 2" class="workbench-bold">Registration</div>

                        </div>
                      </div>
                    </a>
  
                    </td>
                  </tr>
                </ng-template>
  
  
                <ng-template pTemplate="loadingbody">
                  <tr [style]="{'min-height':reRowHeightInPixels,'height':reRowHeightInPixels,'max-height':reRowHeightInPixels}">
                    <td>Loading...</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <!-- <div class="scroll-overflow fill-vert">
              <p-table  [value]="recentEncounters">
                <ng-template pTemplate="header">
                  <tr>
                    <th>
                      Encounters
                      <p-columnFilter type="text" field="phi.lastName" display="menu"></p-columnFilter>
                      <p-button icon="pi pi-bars" (onClick)="filterPanel.toggle($event)"></p-button>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-userEncounter>
                  <tr *ngIf="recentEncounters != null" >
                    <td>
                      <a (click)="showMemberDetails($event,userEncounter)" [style]="{'cursor': 'pointer'}">
                        <div class="workbench-bold">{{userEncounter.phi.lastName}}, {{userEncounter.phi.firstName}}</div>
                        <div class="workbench-bold">{{userEncounter.phi.memberId}}</div>
                        {{userEncounter.myOpenAndPinnedEncounters(currentUserId).length}} | {{userEncounter.totalOpenEncountersCount}}
                        | {{userEncounter.totalEncountersCount}}
                      </a>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div> -->
          </p-tabPanel>
          <p-tabPanel header="Pinned">
            <div *ngIf="pinnedEncounters != null && pinnedTabSelected">
            <p-table [value]="pinnedEncounters.recordsOut" [lazy]="true" (onLazyLoad)="loadMorePinnedEncounters($event)"
              [scrollable]="true" [virtualScroll]="true" dataKey="encounterId" [virtualRowHeight]="peRowHeight" [scrollHeight]="peScrollerHeight" [totalRecords]="pinnedEncounters.totalRecords"
              >
              <ng-template pTemplate="caption">
                
              </ng-template>
              <ng-template pTemplate="body" let-encounter>
                <tr [style]="{'min-height':peRowHeightInPixels,'height':peRowHeightInPixels,'max-height':peRowHeightInPixels}">
                  <td style="min-width: 100%;" class="w12">
                    <a (click)="loadUserEncounterFromPhi(encounter.patient.phi.phiId,encounter.encounterId)" [style]="{'cursor': 'pointer','min-width':'100%'}">
                    <div class="grid" style="min-width: 100%;">
                      <div class="col-6">
                          <div class="workbench-bold">{{encounter.patient?.phi.lastName}}, {{encounter.patient?.phi.firstName}}</div>
                          <div class="workbench-bold">{{encounter.patientPlan?.memberId}}</div>
                      </div>
                      <div class="col-6">
                          <div class="workbench-bold">{{encounter.encounterId}}</div>
                          <div *ngIf="encounter.encounterType.encounterTypeId != 2" class="workbench-bold">{{encounter.category?.categoryName}}</div>
                          <div *ngIf="encounter.encounterType.encounterTypeId == 2" class="workbench-bold">Registration</div>
                      </div>
                    </div>
                  </a>

                  </td>
                </tr>
              </ng-template>


              <ng-template pTemplate="loadingbody">
                <tr [style]="{'min-height':peRowHeightInPixels,'height':peRowHeightInPixels,'max-height':peRowHeightInPixels}">
                  <td>Loading...</td>
                </tr>
              </ng-template>
            </p-table>
            </div>
            <!-- <div class="scroll-overflow fill-vert">
              <!-- <p-scrollPanel [style]="{width: '100%',height: '100%'}" styleClass="sliderbar"> -->
                <!--
                <p-table [value]="pinnedEncounters" sortField="phi.lastName" sortOrder="1">
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="phi.lastName">
                        Encounters<p-sortIcon field="phi.lastName"></p-sortIcon>
                        <p-columnFilter type="text" field="phi.lastName" display="menu"></p-columnFilter>
                        <p-button icon="pi pi-bars" (onClick)="filterPanel.toggle($event)"></p-button>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-userEncounter>
                    <tr *ngIf="pinnedEncounters != null" >
                      <td>
                        <a (click)="showMemberDetails($event,userEncounter)" [style]="{'cursor': 'pointer'}">
                          <div class="workbench-bold">{{userEncounter.phi.lastName}}, {{userEncounter.phi.firstName}}</div>
                          <div class="workbench-bold">{{userEncounter.phi.memberId}}</div>
                          {{userEncounter.myOpenAndPinnedEncounters(currentUserId).length}} | {{userEncounter.totalOpenEncountersCount}}
                          | {{userEncounter.totalEncountersCount}}
                        </a>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div> -->
          </p-tabPanel>

          <p-tabPanel id="tab-mine" header="Mine">
            <div *ngIf="encounterGroupBuffer != null && mineTabSelected" class="fill-vert">
              <p-table  [value]="encounterGroupBuffer.recordsOut" [lazy]="true" (onLazyLoad)="loadMoreEncounterGroups($event)"
              [scrollable]="true" [virtualScroll]="true" dataKey="phiId" [virtualRowHeight]="egRowHeight" [scrollHeight]="egScrollerHeight" [totalRecords]="encounterGroupBuffer.totalRecords"
              >
              <ng-template pTemplate="caption">
              </ng-template>
              <ng-template pTemplate="body" let-encounterGroup> 
                <tr [style]="{'min-height':egRowHeightInPixels,'height':egRowHeightInPixels,'max-height':egRowHeightInPixels}">
                  <td style="min-width: 100%;" class="flex w12">
                    <a (click)="showMemberDetails($event,encounterGroup)" [style]="{'cursor': 'pointer'}">
                      <div class="workbench-bold">{{encounterGroup?.phi.lastName}}, {{encounterGroup?.phi.firstName}}</div>
                      <div *ngIf="encounterGroup?.phi.memberId != null" class="workbench-bold">{{encounterGroup?.phi.memberId}}</div>
                      <div *ngIf="encounterGroup?.phi.memberId == null" class="workbench-bold">[No Active Plan]</div>
                      {{encounterGroup.myOpenAndPinnedEncounters(loggedInUser.userId,userState.pinnedEncounters).length}} | {{encounterGroup.totalOpenEncountersCount}}
                      | {{encounterGroup.totalEncountersCount}}
                    </a>
                  </td>
                </tr>
              </ng-template>


              <ng-template pTemplate="loadingbody">
                <tr [style]="{'min-height':egRowHeightInPixels,'height':egRowHeightInPixels,'max-height':egRowHeightInPixels}">
                    <td>Loading...</td>
                </tr>
              </ng-template>
              </p-table>
            </div>


            <!-- <div class="scroll-overflow fill-vert">
              <!-- <p-scrollPanel [style]="{width: '100%',height: '100%'}" styleClass="sliderbar"> -->
                <!--
                <p-table [value]="myEncounters" sortField="phi.lastName" sortOrder="1">
                  <ng-template pTemplate="header">
                    <tr *ngIf="myEncounters != null">
                      <th pSortableColumn="phi.lastName">
                        Encounters<p-sortIcon field="phi.lastName"></p-sortIcon>
                        <p-columnFilter type="text" field="phi.lastName" display="menu"></p-columnFilter>
                        <p-button icon="pi pi-bars" (onClick)="filterPanel.toggle($event)"></p-button>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-userEncounter>
                    <tr>
                      <td>
                        <a (click)="showMemberDetails($event,userEncounter)" [style]="{'cursor': 'pointer'}">
                          <div class="workbench-bold">{{userEncounter.phi.lastName}}, {{userEncounter.phi.firstName}}</div>
                          <div class="workbench-bold">{{userEncounter.phi.memberId}}</div>
                          {{userEncounter.myOpenAndPinnedEncounters(currentUserId).length}} | {{userEncounter.totalOpenEncountersCount}}
                          | {{userEncounter.totalEncountersCount}}
                        </a>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div> -->
            <!-- </div>       -->
          </p-tabPanel>

        </p-tabView>
      </div>
    </div>
    <!-- <div class="flex-column flex fill-vert"> -->



  </div>
    <!-- </p-scrollPanel> -->
  </div>

  <!-- <div class="col-10 fill-vert">

    <div *ngIf="showUserHomePage" class="grid fill-vert">

      <div class="col-9 shadow-5 p-2">
        <div class="category-header">ENCOUNTERS</div>
      </div>
      <div class="col-3 shadow-5 p-2">
        <div class="category-header">MY DIARIES</div>
 -->



  <div class="col-10 fill-vert">

    <div *ngIf="showUserHomePage" class="grid fill-vert">

      <div class="col-9 shadow-5 p-2">
        <div class="category-header">ENCOUNTERS</div>
        <app-encounter-list (encounterOpened)="loadEntityFromEncounter($event)" (encounterAssigned)="loadEntityFromEncounter($event)" ></app-encounter-list>
      </div>
      
      <div class="col-3 shadow-5 p-2">
        <div class="category-header">MY DIARIES</div>
        <!-- <p-panel header="My Diaries"> -->
          <!-- <p-virtualScroller [value]="diaryBuffer.recordsOut" scrollHeight="500px"
            [lazy]="true"  (onLazyLoad)="loadMoreDiaries($event)" [rows]="bufferSize">
            <ng-template pTemplate="header">My Diaries</ng-template>
            <ng-template pTemplate="item" let-diary>
              <p-card>
                {{diary.diaryId}}
              </p-card>
            </ng-template>
            <ng-template pTemplate="loadingItem" let-diary>
                Loading ...
            </ng-template>
            <ng-template pTemplate="footer">Footer Content</ng-template>
        </p-virtualScroller> -->

        <!-- <app-diary-scroller [allowNew]="false" [getForCurrentUser]="true" [showReferenceLinks]="true" (loadDiaryReference)="loadEntityFromDiary($event)" [allowStatusSelection]="false"></app-diary-scroller> -->
        
        <!-- <p-table [value]="diaryBuffer.recordsOut" [lazy]="true" (onLazyLoad)="loadMoreDiaries($event)"
          [scrollable]="true" [virtualScroll]="true" [rows]="2" [virtualRowHeight]="170" scrollHeight="650px">
          <ng-template pTemplate="header"><th>My Diaries</th></ng-template>
          <ng-template pTemplate="body" let-diary>
            <tr style="min-height: 170px; height:170px; max-height: 170px;"><td>
              <p-card [style]="{'width':'100%'}">
                <div class="grid flex">
                  <div class="col-7">
                    Due: <b>{{diary.diaryDueDate | date: 'shortDate'}}</b><br>
                    <b>{{diary.target?.patient.phi.firstName}} {{diary.target?.patient.phi.lastName}}</b>
                  </div>
                  <div class="col-3">
                    Enc: <b>{{diary.referenceId}}</b><br>
                  </div>
                  <div class="col-2" style="text-align: right;">
                    <p-tag value="Open" icon="pi pi-id-card" (click)="loadEntityFromDiary($event,diary)" [style]="{'cursor': 'pointer'}"></p-tag>
                  </div>
                  <div class="col-11">
                    <p-card [style]="{'background':'lightyellow'}">
                    {{tcemsUtility.shortenDescription(diary.shortDescription,150)}}
                    </p-card>
                  </div>
                  <div class="col-1">
                      <p-dialog appendTo="body" header="{{diary.shortDescription}}" [(visible)]="diaryDialogs[diary.diaryId]"
                                [modal]="false" [dismissableMask]="true" [closeOnEscape]="true" [responsive]="true"
                                [closable]="true" [style]="{width: '50%'}">

                        <p-editor [(ngModel)]="diary.diaryText" [style]="{'height':'320px'}" readonly="true">
                          <ng-template pTemplate="header">
                            Due: <b>{{diary.diaryDueDate | date: 'shortDate'}}</b> &middot;
                            Assigned: <b>{{diary.assignedTo.firstName}} {{diary.assignedTo.lastName}}</b> &middot;
                            Created: <b>{{diary.createdBy.firstName}} {{diary.createdBy.lastName}}</b> ({{diary.createDate | date: 'shortDate'}}) &middot;
                            Enc: {{diary.referenceId}}
                          </ng-template>
                        </p-editor>
                    </p-dialog>
                    <button pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded" (click)="showDiaryDialog(diary.diaryId)"></button>
                    </div>
                </div>
              </p-card>
            </td></tr>
          </ng-template>
          <ng-template pTemplate="loadingbody">
            <tr style="min-height: 170px; height: 170px; max-height: 170px;">
              <td>
                <div class="flex justify-content-center" style="width: 100%;">
                  <p-progressSpinner strokeWidth="8" animationDuration=".5s"></p-progressSpinner>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table> -->

        <!-- </p-panel> -->

      </div>
    </div>

    <div *ngIf="!showUserHomePage" class="fill-vert scroll-overflow" >
      <span pTooltip="Member Has No Active, Eligible Plan" [tooltipDisabled]="hasActivePlan">
        <!-- <button pButton pRipple type="button" label="New Encounter"  (click)="newEncounter()" class="p-button-rounded"
                [disabled]="!hasActivePlan || currentEncounterGroup.phi.hasOptedOut"></button> -->
        <p-splitButton label="New Encounter" (onClick)="newEncounter()" styleClass="p-button-rounded" [disabled]="!hasActivePlan" [model]="encounterCreateOptions"></p-splitButton>
        
        <button pButton pRipple *ngIf="!showClosedEncounters" type="button" label="Show Closed Encounters" (click)="showClosedEncounters=!showClosedEncounters;" class="p-button-rounded"></button>
        <button pButton pRipple *ngIf="showClosedEncounters" type="button" label="Hide Closed Encounters" (click)="showClosedEncounters=!showClosedEncounters;" class="p-button-rounded"></button>
      </span>
      <button pButton pRipple *ngIf="currentEncounterGroup != null && currentEncounterGroup.phi.hasOptedOut" type="button" label="Member Has Opted Out of Program"
              class="p-button-rounded p-button-danger"></button>

      <!-- <p-tag [style]="{'cursor':'pointer'}">
        New Encounter
      </p-tag>&nbsp;
      <p-tag *ngIf="currentMember.closedEncounters().length >= 1" (click)="showClosedEncounters=!showClosedEncounters;" [style]="{'cursor':'pointer'}">
        <span *ngIf="!showClosedEncounters">Show</span>
        <span *ngIf="showClosedEncounters">Hide</span>
        &nbsp;Closed Encounters
      </p-tag> -->

      <p-tabView *ngIf="currentEncounterGroup != null" (onChange)="handleEncounterTabChange($event)" [(activeIndex)]="tabIndex" [scrollable]="true">
        <h3 class="p-2" [ngStyle]="{'border':'1px solid black','padding':'0 5px',
          'background':'linear-gradient(60deg,rgba('+(whiteLabelPersonality?.background.R??255)+','+(whiteLabelPersonality?.background.G??255)+','+(whiteLabelPersonality?.background.B??255)+','+(whiteLabelPersonality?.background.O??1)+') 20%,
          rgba('+(whiteLabelPersonality?.secondary.R??255)+','+(whiteLabelPersonality?.secondary.G??255)+','+(whiteLabelPersonality?.secondary.B??255)+','+(whiteLabelPersonality?.secondary.O??1)+')'}">
<!--          'background-color':'rgba('+(whiteLabelPersonality?.background.R??255)+','+(whiteLabelPersonality?.background.G??255)+','+(whiteLabelPersonality?.background.B??255)+','+(whiteLabelPersonality?.background.O??1)+')'}">-->
          <img src="{{'assets/images/'+whiteLabelPersonality.logo}}" height="20px">
          <span class="p-2" [ngStyle]="{'font-size':'20px','color':'rgba('+(whiteLabelPersonality?.text.R??0)+','+(whiteLabelPersonality?.text.G??0)+','+(whiteLabelPersonality?.text.B??0)+','+(whiteLabelPersonality?.text.O??1)+')'}">
            {{currentEncounterGroup.phi.firstName}} {{currentEncounterGroup.phi.middleInitial}} {{currentEncounterGroup.phi.lastName}}
          </span>
        </h3>
          <!-- <p-overlayPanel #missingEmailOverlay [appendTo]="body">
            <ng-template pTemplate>
                Member Needs Email
            </ng-template>
          </p-overlayPanel>    -->
        <!-- Member Details Tab -->
        <p-tabPanel >
          <ng-template  pTemplate="header" style="font-weight: bold; ">
              <!-- <div ng-init="if (missingEmail) missingEmailOverlay.show($event); else missingEmailOverlay.hide()"> -->
              <!-- <div pTooltip="Needs Email Address" [tooltipDisabled]="!missingEmail" appendTo="body"> -->
                <div class="flex grid detail-tab" style="background-color: lightcyan;">
                <div class="p-col" style="color:black;">Member<br>Details</div></div>


              
              <!-- </div> -->
          </ng-template>

  


          <ng-template pTemplate="content">
            <div class="fill-vert">
              <app-member-details-page [currentMember]="currentEncounterGroup.phi" (currentMemberChange)="currentMemberChanged($event)"
              (optOutStatusChange)="toggleOptOut($event)"></app-member-details-page>
                                      <!-- [currentMemberSubscriber]="currentMemberSubscriber"
                                      (currentMember)="this.currentEncounterGroup.phi = $event"></app-member-details-page> -->
            </div>
          </ng-template>
        </p-tabPanel>

        <!-- Owned Open and Pinned Encounters -->
        <p-tabPanel *ngFor="let myEncounter of currentEncounterGroup.myOpenEncounters(this.currentUserId).reverse()">
          <ng-template pTemplate="header">
            <div class="flex grid  detail-tab encounterTabMine">
              <div class="col-12" style="color:black">
                <span *ngIf="isPinnedEncounter(myEncounter.encounterId)">&#9733;</span>
                <span *ngIf="myEncounter.encounterType.encounterTypeId != 2">{{myEncounter.category?.categoryName}}</span>
                <span *ngIf="myEncounter.encounterType.encounterTypeId == 2">[Registration]</span>
                <div style="float: right">&nbsp;
                  <span *ngIf="myEncounter.encounterType?.encounterTypeId == 2" class="pi pi-dollar"></span>
                  <span *ngIf="myEncounter.encounterType?.encounterTypeId == 6" style="font-size: 1.5em;">&copysr;</span>
                </div><br>
                ({{myEncounter.encounterId}}) {{myEncounter.creationDate |date:'shortDate'}}<br>
                <span *ngIf="myEncounter.assignedTo?.userId !== loggedInUser.userId">{{myEncounter.assignedTo?.firstName.substr(0,1)}} {{myEncounter.assignedTo?.lastName}}</span>
                <span *ngIf="myEncounter.assignedTo == null">[not assigned]</span>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <button pButton pRipple type="button" [icon]="isPinnedEncounter(myEncounter.encounterId) ? 'pi pi-star' : 'pi pi-star-o'"
                    (click)="togglePin($event,myEncounter)" class="p-button-rounded p-button-outlined"
                    style="float:left;z-index:2;left:5px;top:5px;"></button>
            <div class="fill-vert">
              <app-encounter-details-page [encounter]="myEncounter"
                (encounterProviderAdded)="addEncounterProvider(myEncounter, $event)"
                (encounterProviderRemoved)="removeEncounterProvider(myEncounter,$event)"
                (categorySet)="setEncounterCategory(myEncounter,$event)"
                (statusChange)="encounterStatusChanged(myEncounter,$event)"></app-encounter-details-page>
            </div>
          </ng-template>
        </p-tabPanel>

        <!-- Other Open Encounters -->
        <p-tabPanel *ngFor="let openEncounter of currentEncounterGroup.otherOpenEncounters(this.currentUserId).reverse()" >
          <ng-template pTemplate="header">
            <div class="flex grid detail-tab encounterTabOtherOpen">
              <div class="col-12" style="color:black">
                <span *ngIf="isPinnedEncounter(openEncounter.encounterId)">&#9733;</span>
                <span *ngIf="openEncounter.encounterType.encounterTypeId != 2">{{openEncounter.category?.categoryName}}</span>
                <span *ngIf="openEncounter.encounterType.encounterTypeId == 2">[Registration]</span>
                <div style="float: right">&nbsp;
                  <span *ngIf="openEncounter.encounterType?.encounterTypeId == 2" class="pi pi-dollar"></span>
                  <span *ngIf="openEncounter.encounterType?.encounterTypeId == 6" style="font-size: 1.5em;">&copysr;</span>
                </div><br>
                ({{openEncounter.encounterId}}) {{openEncounter.creationDate |date:'shortDate'}}<br>
                <span *ngIf="openEncounter.assignedTo?.userId !== loggedInUser.userId">{{openEncounter.assignedTo?.firstName.substr(0,1)}} {{openEncounter.assignedTo?.lastName}}</span>
                <span *ngIf="openEncounter.assignedTo == null">[not assigned]</span>
              </div>
          </div>
          </ng-template>
          <ng-template pTemplate="content">
            <button pButton pRipple type="button" [icon]="isPinnedEncounter(openEncounter.encounterId) ? 'pi pi-star' : 'pi pi-star-o'"
                    (click)="togglePin($event,openEncounter)" class="p-button-rounded p-button-outlined"
                    style="float:left;z-index:2;left:5px;top:5px;"></button>
            <div class="fill-vert">
              <app-encounter-details-page [encounter]="openEncounter"
              (encounterProviderAdded)="addEncounterProvider(openEncounter, $event)"
              (encounterProviderRemoved)="removeEncounterProvider(openEncounter,$event)"
              (categorySet)="setEncounterCategory(openEncounter,$event)"
              (statusChange)="encounterStatusChanged(openEncounter,$event)"></app-encounter-details-page>
            </div>
          </ng-template>
        </p-tabPanel>

        <!-- Closed Encounters -->
        <ng-container *ngIf="showClosedEncounters">
        <p-tabPanel *ngFor="let closedEncounter of currentEncounterGroup.closedEncounters.reverse()">
          <ng-template pTemplate="header">
            <div class="flex grid detail-tab encounterTabOther" >
              <div class="col-12" style="color:black">
                <span *ngIf="isPinnedEncounter(closedEncounter.encounterId)">&#9733;</span>

                <span *ngIf="closedEncounter.encounterType.encounterTypeId != 2">{{closedEncounter.category?.categoryName}}</span>
                <span *ngIf="closedEncounter.encounterType.encounterTypeId == 2">[Registration]</span>
                <div style="float: right">&nbsp;
                  <span *ngIf="closedEncounter.encounterType?.encounterTypeId == 2" class="pi pi-dollar"></span>
                  <span *ngIf="closedEncounter.encounterType?.encounterTypeId == 6" style="font-size: 1.5em;">&copysr;</span>
                </div><br>
                ({{closedEncounter.encounterId}}) {{closedEncounter.creationDate |date:'shortDate'}}<br>
                <span *ngIf="closedEncounter.assignedTo?.userId !== loggedInUser.userId">{{closedEncounter.assignedTo?.firstName.substr(0,1)}} {{closedEncounter.assignedTo?.lastName}}</span>
                <span *ngIf="closedEncounter.assignedTo == null">[not assigned]</span>
              </div>
          </div>
          </ng-template>
          <ng-template pTemplate="content">
            <button pButton pRipple type="button" [icon]="isPinnedEncounter(closedEncounter.encounterId) ? 'pi pi-star' : 'pi pi-star-o'"
                    (click)="togglePin($event,closedEncounter)" class="p-button-rounded p-button-outlined"
                    style="float:left;z-index:2;left:5px;top:5px;"></button>
            <div class="fill-vert">
              <app-encounter-details-page [encounter]="closedEncounter"
              (encounterProviderAdded)="addEncounterProvider(closedEncounter, $event)"
              (encounterProviderRemoved)="removeEncounterProvider(closedEncounter,$event)"
              (categorySet)="setEncounterCategory(closedEncounter,$event)"
              (statusChange)="encounterStatusChanged(closedEncounter,$event)"></app-encounter-details-page>
            </div>
          </ng-template>
        </p-tabPanel>
        </ng-container>

      </p-tabView>
      

    </div>

  </div>

</div>